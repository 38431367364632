.marquee-slider .slick-slider button{
    background-color: #3969d5;
    /* border-radius: 50%; */
    padding: 4px;
}
.marquee-slider .slick-slider button.slick-prev:before, .marquee-slider .slick-slider button.slick-next:before{
    color: #ffffff;
    opacity: 1;
    font-size: 14px;
}
.marquee-slider .slick-slider button.slick-prev:before{
    content: '';
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid #ffffff;
    font-size: 1px;
}
.marquee-slider .slick-slider button.slick-next:before{
    content: '';
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #ffffff;
    font-size: 1px;
}
.marquee-slider .slick-slider .slick-slide{
    max-width: 200px;
}
.marquee-slider .slick-slider .indice-parent {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 1px solid rgba(57, 105, 213, 0.2);
    padding: 10px;
    max-width: 215px;
    height: 53px;
}

.indice-small-font {
  font-size: 14px;
}

.indice-title {
    font-size: 16px;
    font-weight: 600;
}
.indice-total-number {
    color: #848484;
    font-weight: 400;
}

.up-value {
    display: flex;
    align-items: center;
    color: #E12727;
}

.up-value.negative {
    color: #E12727
}

.up-value.positive {
    color: #3fb61a;
}

.down-value {}