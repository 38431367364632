
.custom-search-bar input {
    border-radius: 7px;
    border: 1px solid #3969D5;
    padding: 0.5em;
    position: relative;
}

.custom-search-bar .search-bar-icon {
    position: absolute;
    left: 1px;
}

.searchbar-dropdown-wrapper {
    /* -webkit-box-shadow: 0px 5px 4px 2px rgba(0,0,0,0.4); 
    box-shadow: 0px 5px 4px 2px rgba(0,0,0,0.4); */
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    /* padding: 0em 0.7em; */
}

.searchbar-dropdown-item {
    padding: 0.5em 1.5em;
}

.searchbar-dropdown-item:hover {
    background-color: #8080803b;
    cursor: pointer;
}

.searchbar-dropdown-item p {
    margin: 0;
    padding: 0;
}
.search-main {
    width: 100%;
    display: inline-block;
    background-color: transparent!important;
    margin: 10px 0;
    position: relative;
}
.search-main .search-field{
    margin: 0;
}
.search-main .search-field input{
    border: 1px solid #ececec;
}
.dashboard-main.dark .search-main .search-field input{
    background: transparent;
    color: #ffffff;
}
.search-main .search-field input::placeholder{
    color: #c7c7c7;
}
.search-main .search-field input:focus{
    box-shadow: none;
}
.search-main .search-field{
    position: relative;
}
.search-main .search-field .icon-cancel{
    line-height: 1;
    padding: 5px;
    background: #ffffff;
    border-radius: 50%;
    width: 25px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px #ececec;
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
}
.icon-cancel img{
    width: 10px;
    height: 10px;
    color: #fff;
}
.search-main .search-field .icon-search{
    line-height: 1;
    padding: 5px;
    background: #ffffff;
    border-radius: 50%;
    width: 25px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0 0 10px #ececec; */
    position: absolute;
    right: 5px;
    top: 10px;
    /* cursor: pointer; */
}
.dashboard-main.dark .search-main .search-field .icon-search{
    background: transparent;
}
.search-main .search-field .icon-search img{
    width: 15px;
    height: 15px;
    opacity: 0.5;  
}
.search-main .searchbar-dropdown-wrapper{
    display: none;
    border-radius: 0 0 5px 5px;
    overflow: none;
    max-height: 500px;
    padding: 10px;
    position: absolute;
    background: #ffffff;
    z-index: 999;
    right: 0;
    left: 0;
    border: 1px solid #9cb6ea;
    border-top: 0px;
    box-shadow: 5px 5px 10px #9cb6ea6b;
    overflow-y: auto;
}
.search-main .searchbar-dropdown-wrapper.active{
    display: block;
}
.search-main .searchbar-dropdown-wrapper::-webkit-scrollbar-track{
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: #F5F5F5;
}
.search-main .searchbar-dropdown-wrapper::-webkit-scrollbar{
    width: 2px;
    height: 4px;
    background-color: rgb(57 109 213 / 50%);
}
.search-main .searchbar-dropdown-wrapper::-webkit-scrollbar-thumb{
    background-color: rgb(57 109 213 / 50%);
}
/* .search-main .searchbar-dropdown-wrapper > div:first-child{
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
} */
/* .search-main .searchbar-dropdown-wrapper > div:first-child h4{
    margin: 10px auto;
    border-bottom: 1px solid #ececec;
    font-weight: 700;
} */
.search-main .searchbar-dropdown-wrapper .searchbar-dropdown-item{
    border-bottom: 0.25px solid #ededed;
}
.search-main .searchbar-dropdown-wrapper .searchbar-dropdown-item .sector{
    font-size: 13px;
}
.search-main .searchbar-dropdown-wrapper .searchbar-dropdown-item p{
    color: #484848;
}
.search-main .searchbar-dropdown-wrapper .searchbar-dropdown-item p strong{
    color: rgb(57 105 213 / 90%);
}
@media only screen and (max-width: 991px){
    .search-main{
        width: 100%;
    }
}