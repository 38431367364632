.watchlist-title{
    color: #3d3d3d;
    font-size: 24px;
    font-weight: 600;
}
.watchlist-table-card{
    border: var(--light-border);
    border-radius: 0px;
    margin-bottom: 15px;
    border-bottom: none;
}
.watchlist-table-card .watchlist-table-card-body {
    min-width: 100%;
    overflow-x: scroll;
}
.watchlist-table-card .table{
    margin: 0px;
    min-width: 991px;
    overflow-x: scroll;
}
.watchlist-table-card .table thead{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-black);
    border-bottom: 1.5px solid var(--light-border-blue);
}
.watchlist-table-card .table thead tr th:first-child{
    text-align: left;
    padding-left: 15px;
}
.watchlist-table-card .table thead tr th{
    text-align: center;
    color: var(--light-black);
    font-weight: 500;
}
.watchlist-table-card .table tbody{
    border: none;
}
.watchlist-table-card .table tbody tr{
    border-style: none;
    border-width: 0px;
    border-bottom: var(--light-border);
}
.watchlist-table-card .table tbody tr td{
    color: var(--light-black);
    font-size: 12px;
    font-weight: 400;
    border: none;
    text-align: center;
}
.watchlist-table-card .table tbody tr td:first-child{
    text-align: left;
    padding-left: 15px;
}
.watchlist-table-card .table tbody tr td{
    vertical-align: middle;
}
.watchlist-table-card .table tbody tr td p{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.watchlist-table-card .table tbody tr td span{
    color: var(--primary-blue);
    font-weight: 400;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    line-height: 1;
}
.watchlist-table-card .table tbody tr td:first-child span{
    font-weight: 700;
    color: var(--primary-blue);
}
.watchlist-table-card .table tbody tr td.remove-action span{
    width: 18px;
    height: 18px;
    font-size: 8px;
    text-transform: capitalize;
    color: #ffffff;
    background-color: #e54242;
    border-radius: 5px;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;
}
.watchlist-table-card .table tbody tr td.remove-action span:hover{
    transform: scale(1.01);
    border-radius: 0;
    transition: 0.3s all ease-in-out;
}

.watchlist-tabs-view{
    position: relative;
}
.watchlist-tabs-view .nav-tabs{
    width: calc(100% - 175px);
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid #ededed;
    border-radius: 0px;
}
.watchlist-tabs-view .nav-tabs .nav-link{
    padding: 5px;
    display: inline-block;
    min-width: 100px;
    font-size: 14px;
    color: #3d3d3d;
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.watchlist-tabs-view .nav-tabs .nav-item.show .nav-link, .watchlist-tabs-view .nav-tabs .nav-link.active{
    border: none;
    color: #3969d5;
    font-weight: 500;
    border: 0.25px solid #ededed;
    border-top: 2px solid #3969d5;
    border-radius: 0px;
}
.watchlist-tabs-view .btn.btn-light{
    color: #3969d5;
    position: absolute;
    right: 0;
    font-size: 14px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.watchlist-tabs-view .btn.btn-light:hover{
    background-color: transparent;
}
.watchlist-tabs-view .btn.btn-light:focus{
    box-shadow: none;
}


.watchlist-tabs-view .slider-tab-block{
    padding-right: 200px;
    padding-left: 25px;
    position: relative;
}
.watchlist-tabs-view .slider-tab-block .first-nav-link{
    position: absolute;
    top: 0;
    left: 25px;
    z-index: 99;
    width: 151px;
    background: #ffffff;
    text-align: center;
    padding: 6px 15px;
    display: inline-block;
    min-width: 100px;
    font-size: 14px;
    color: #3d3d3d;
    max-width: 175px;
    border: 0.25px solid #ededed;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.watchlist-tabs-view .slider-tab-block .first-nav-link.active{
    border: none;
    color: #3969d5;
    font-weight: 500;
    border: 0.25px solid #ededed;
    border-bottom: 2px solid #3969d5;
    border-radius: 0px;
}
/* .watchlist-tabs-view .slider-tab-block .slick-list{
    padding-left: 151px;
} */
.watchlist-tabs-view .slider-tab-block .slick-track{
    margin-left: 0px;
    /* display: flex; */
}
/* .watchlist-tabs-view .slider-tab-block .slick-track .slick-slide{
    min-width: 150px!important;
} */
/* 
.watchlist-tabs-view .slider-tab-block .slick-slide:first-child{
    position: absolute;
    left: 0;
} */
.watchlist-tabs-view .slider-tab-block .btn.btn-light{
    color: #3969d5;
    position: absolute;
    right: 0;
    font-size: 14px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.watchlist-tabs-view .slider-tab-block .btn.btn-light:hover{
    background-color: transparent;
}
.watchlist-tabs-view .slider-tab-block .btn.btn-light:focus{
    box-shadow: none;
}
.watchlist-tabs-view .slider-tab-block .slick-slider button{
    background-color: #3969d5;
    /* border-radius: 50%; */
    padding: 4px;
    display: block;
    top: calc(50% - 3px)!important;
}
.watchlist-tabs-view .slider-tab-block .slick-slider button.slick-prev:before, .watchlist-tabs-view .slider-tab-block .slick-slider button.slick-next:before{
    color: #ffffff;
    opacity: 1;
    font-size: 14px;
}
.watchlist-tabs-view .slider-tab-block .slick-slider button.slick-prev:before{
    content: '';
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid #ffffff;
    font-size: 1px;
}
.watchlist-tabs-view .slider-tab-block .slick-slider button.slick-next:before{
    content: '';
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #ffffff;
    font-size: 1px;
}
.watchlist-tabs-view .slider-tab-block .slick-slider .slick-slide div span{
    text-align: center;
    padding: 6px 15px;
    display: inline-block;
    min-width: 100px;
    /* min-width: 151px; */
    font-size: 14px;
    color: #3d3d3d;
    max-width: 175px;
    border: 0.25px solid #ededed;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.watchlist-tabs-view .slider-tab-block .slick-slider .slick-slide div span.active{
    border: none;
    color: #3969d5;
    font-weight: 500;
    border: 0.25px solid #ededed;
    border-bottom: 2px solid #3969d5;
    border-radius: 0px;
}