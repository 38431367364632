:root {
  --primary-black: #3d3d3d;
  --primary-blue: #3969d5;
  --primary-blue-light: #3968d5bb;
  --light-black: #484848e8;
  --white: #ffffff;
  
  --light-border: 0.25px solid rgb(57 109 213 / 30%);
  --light-border-blue: #3969d5a6;
  --sharp-border-color: #396dd575; 
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.029);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.029);
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 2px;
  height: 6px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  background-color: #3969D5;
}

/* ::-webkit-scrollbar-button:single-button {
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} */
/* *::-webkit-scrollbar-button:single-button:increment {
  background-color: #3969D5;
  display: block;
  border: 1px solid #3969D5;
  border-radius: 0 5px 5px 0;
  height: 13px;
  width: 16px;
}
*::-webkit-scrollbar-button:single-button:decrement {
  background-color: #3969D5;
  display: block;
  border: 1px solid #3969D5;
  border-radius: 5px 0 0 5px;
  height: 13px;
  width: 16px;
} */

.custom-container {
    /* margin-left: 5%;
    margin-right: 5%; */
    margin-left: 30px;
    margin-right: 30px;
}

.modal-dialog .modal-content {
  border: none;
  border-radius: 10px;
}
.modal-dialog .modal-header {
  padding: 15px 15px 10px;
}
.modal-dialog .modal-header .modal-title {
  color: #080404ab;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
.form-group{
  margin-bottom: 15px;
}
.field-error {
  color: red;
  font-size: 12px;
  position: absolute;
}
.primary-input label {
  color: #080404ab;
  position: relative;
  width: 100%;
}
.primary-input input {
  color: #adadad;
}
.primary-input input::placeholder{
  color: #b6b6b6;
}
.btn {
  font-size: 14px!important;
  font-weight: 400;
  height: 35px;
}
.btn-primary {
  background-color: #3969d5!important;
}
.btn-cancel {
  margin-left: 10px;
  color: #080404ab!important;
  background: transparent;
  /* border: 0.25px solid var(--light-border-color); */
  border: .25px solid rgba(57,109,213,.2)!important;
}